document.addEventListener("contextmenu", function (event) {
  event.preventDefault();
});

document.addEventListener("keydown", function (e) {
  // Block F12
  if (e.key === "F12") {
    e.preventDefault();
  }
  // Block Ctrl+Shift+I
  if (e.ctrlKey && e.shiftKey && e.key === "I") {
    e.preventDefault();
  }
  // Block Ctrl+Shift+C
  if (e.ctrlKey && e.shiftKey && e.key === "C") {
    e.preventDefault();
  }
});

import "./style.css";
import * as THREE from "three";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";

import { Capsule } from "three/examples/jsm/math/Capsule.js";

const slot = document.querySelectorAll(".slot");
const closeSettings = document.getElementById("closeSettings");
const fullscreen = document.getElementById("fullscreen");
const settings = document.querySelector(".settings");
const shadowCasting = document.getElementById("shadowCasting");
const hud = document.getElementById("hud");
const controlsHud = document.querySelector(".controls");

let fps;

let canvas, camera, scene, renderer, controls;
// let gui;
let textures;
let cubeGeo, slabGeo, cube;
let currentBlock = 0,
  heldBlock;
let outlineMat, outlineMesh;
let pointer, raycaster, intersect, intersections;

let directionalLight;

let locked,
  isPaused = true;

let keyboard = {};

let xyz = [];

let objects = [];

let worldName, worldImage, worldDate, world;

let rndTexture = false;

let renderDistance = 20;

let index = 3;
let shiftClicks = 0;
const ratios = [0.25, 0.5, 0.75, 1, 1.5, 2];

let blocks;

const clock = new THREE.Clock();

init();

function init() {
  textures = new THREE.TextureLoader();

  canvas = document.querySelector("canvas.webgl");

  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x88ccee);
  scene.fog = new THREE.Fog(0x88ccee, renderDistance - 10, renderDistance);

  cubeGeo = new THREE.BoxBufferGeometry(1, 1, 1);

  blocks = {
    oak_planks: {
      texture: [
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_planks.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_planks.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_planks.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_planks.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_planks.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_planks.png"),
        }),
      ],
      rotate: false,
      transparent: false,
    },
    oak_log: {
      texture: [
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_log.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_log.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_log_top.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_log_top.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_log.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_log.png"),
        }),
      ],
      rotate: true,
      transparent: false,
    },
    grass_block: {
      texture: [
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/grass_block_side.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/grass_block_side.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/grass_block_top.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/dirt.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/grass_block_side.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/grass_block_side.png"),
        }),
      ],
      rotate: false,
      transparent: false,
    },
    dirt: {
      texture: [
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/dirt.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/dirt.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/dirt.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/dirt.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/dirt.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/dirt.png"),
        }),
      ],
      rotate: false,
      transparent: false,
    },
    cobblestone: {
      texture: [
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/cobblestone.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/cobblestone.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/cobblestone.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/cobblestone.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/cobblestone.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/cobblestone.png"),
        }),
      ],
      rotate: false,
      transparent: false,
    },
    glass: {
      texture: [
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/glass.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/glass.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/glass.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/glass.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/glass.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/glass.png"),
        }),
      ],
      rotate: false,
      transparent: true,
    },
    oak_leaves: {
      texture: [
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_leaves.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_leaves.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_leaves.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_leaves.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_leaves.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: textures.load("textures/oak_leaves.png"),
        }),
      ],
      rotate: false,
      transparent: true,
    },
  };

  for (let blockName in blocks) {
    let block = blocks[blockName];
    block.texture.forEach((blockTexture) => {
      blockTexture.map.minFilter = THREE.LinearFilter;
      blockTexture.map.magFilter = THREE.NearestFilter;
      blockTexture.transparent = block.transparent;
      blockTexture.side = THREE.Front;
    });
  }

  document.addEventListener("keydown", (event) => {
    if (
      event.key === "1" ||
      event.key === "2" ||
      event.key === "3" ||
      event.key === "4" ||
      event.key === "5" ||
      event.key === "6" ||
      event.key === "7"
    ) {
      const activeSlot = document.querySelector(".slot.active");
      currentBlock = event.key - 1;
      activeSlot.classList.remove("active");
      slot[currentBlock].classList.add("active");
    }
  });

  outlineMat = new THREE.MeshBasicMaterial({
    color: 0x000000,
    opacity: 0.25,
    transparent: true,
    depthWrite: false,
  });

  outlineMesh = new THREE.Mesh(cubeGeo, outlineMat);
  outlineMesh.scale.set(1.005, 1.005, 1.005);
  scene.add(outlineMesh);

  heldBlock = new THREE.Mesh(
    cubeGeo,
    blocks[Object.keys(blocks)[currentBlock]].texture
  );

  raycaster = new THREE.Raycaster();
  pointer = new THREE.Vector2();

  // Lights

  const ambientLight = new THREE.AmbientLight(0x606060);
  scene.add(ambientLight);

  directionalLight = new THREE.DirectionalLight(0xffffff);
  directionalLight.position.set(50, 50, 50);
  directionalLight.castShadow = true;
  directionalLight.shadow.camera.near = 0;
  directionalLight.shadow.camera.far = 500;
  directionalLight.shadow.camera.right = 50;
  directionalLight.shadow.camera.left = -50;
  directionalLight.shadow.camera.top = 50;
  directionalLight.shadow.camera.bottom = -50;
  directionalLight.shadow.mapSize.width = 1024;
  directionalLight.shadow.mapSize.height = 1024;
  directionalLight.shadow.radius = 2;
  scene.add(directionalLight);

  createWorld();
  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(ratios[index]);
  });

  /**
   * Camera
   */
  // Base camera

  camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.001,
    5000
  );
  camera.position.y = 2;
  scene.add(camera);

  heldBlock.scale.set(0.5, 0.5, 0.5);
  heldBlock.position.set(1, -0.75, -1);
  heldBlock.rotation.set(THREE.Math.degToRad(0), THREE.Math.degToRad(30), 0);
  camera.add(heldBlock);

  /**
   * Renderer
   */
  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
    preserveDrawingBuffer: true,
  });
  renderer.setPixelRatio(ratios[index]);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.VSMShadowMap;
  renderer.shadowMap.autoUpdate = false;
  controls = new PointerLockControls(camera, canvas);

  controls.maxPolarAngle = Math.PI - 0.005;
  controls.minPolarAngle = 0.005;

  closeSettings.addEventListener(
    "click",
    () => {
      controls.lock();
    },
    false
  );

  initializeSettings();

  controls.addEventListener("lock", () => {
    locked = true;
    if (fullscreen.checked === true) {
      document.documentElement.requestFullscreen();
    }
    settings.style.display = "none";
    canvas.style.filter = "brightness(1)";
    isPaused = false;
  });

  controls.addEventListener("unlock", () => {
    locked = false;
    settings.style.display = "flex";
    settings.classList.remove("worldSave");
    settings.classList.remove("worldLoad");
    settings.classList.remove("settingsScreen");
    settings.classList.remove("error");
    canvas.style.filter = "brightness(0.75)";
    isPaused = true;
  });
}

function destroyWorld() {
  for (let i = 0; i < objects.length; i++) {
    objects[i].material.forEach((element) => {
      element.dispose();
    });
    objects[i].geometry.dispose();
    scene.remove(objects[i]);
  }
  objects = [];

  renderer.shadowMap.needsUpdate = true;
}

function createWorld() {
  if (objects.length != 0) {
    destroyWorld();
  }
  for (let i = -49; i < 50; i++) {
    for (let j = -49; j < 50; j++) {
      // let randTexture = Math.floor(Math.random() * blockTextures.length);
      const floor = new THREE.Mesh(
        cubeGeo,
        blocks[Object.keys(blocks)[2]].texture
      );
      floor.position.set(i, 0, j);
      floor.receiveShadow = true;
      floor.userData = {
        texture: 2,
      };
      scene.add(floor);
      objects.push(floor);
    }
  }
  // renderer.shadowMap.needsUpdate = true;
}

function loadWorldFromFile(worldData) {
  destroyWorld();

  for (let i = 0; i < worldData[3].length; i++) {
    let block = new THREE.Mesh(
      cubeGeo,
      blocks[Object.keys(blocks)[worldData[3][i][1]]].texture
    );
    block.position.set(
      worldData[3][i][0].x,
      worldData[3][i][0].y,
      worldData[3][i][0].z
    );
    if (worldData[3][i][2] != undefined) {
      block.userData = {
        texture: worldData[3][i][1],
        rotation: worldData[3][i][2],
      };
      if (block.userData.rotation == 1) {
        block.rotateOnAxis(new THREE.Vector3(0, 0, 1), -Math.PI / 2);
      } else if (block.userData.rotation == 2) {
        block.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
      }
    } else {
      block.userData = {
        texture: worldData[3][i][1],
      };
    }
    block.receiveShadow = true;
    scene.add(block);
    objects.push(block);
  }
}

function getWorldData(worldData) {
  let newData = [];
  worldData.forEach((block, index) => {
    if (block.userData.rotation != undefined) {
      newData[index] = [
        block.position,
        block.userData.texture,
        block.userData.rotation,
      ];
    } else {
      newData[index] = [block.position, block.userData.texture];
    }
  });
  return newData;
}

function initializeSettings() {
  document.getElementById("resolution").addEventListener("click", (event) => {
    index++;
    if (index >= ratios.length) {
      index = 0;
    }
    renderer.setPixelRatio(ratios[index]);
    const text = `Resolution: ${ratios[index]}x`;
    document.getElementById("resolution").textContent = text;
    if (event.shiftKey) {
      shiftClicks++;
      if (shiftClicks >= 5) {
        renderer.setPixelRatio(0.05);
        document.getElementById("resolution").textContent = "Resolution: 36p";
      }
    } else {
      shiftClicks = 0;
    }
  });

  document.getElementById("renderDistance").addEventListener("input", () => {
    renderDistance = document.getElementById("renderDistance").value;
    document.getElementById("rangeValue").innerHTML =
      document.getElementById("renderDistance").value + " blocks";
    if (fog.checked === true)
      scene.fog = new THREE.Fog(0x88ccee, renderDistance - 10, renderDistance);
  });

  shadowCasting.addEventListener("change", () => {
    shadowCasting.checked
      ? (directionalLight.castShadow = true)
      : (directionalLight.castShadow = false);
  });

  hud.addEventListener("change", () => {
    hud.checked
      ? (controlsHud.style.display = "flex")
      : (controlsHud.style.display = "none");
  });

  fog.addEventListener("change", () => {
    fog.checked
      ? (scene.fog = new THREE.Fog(
          0x88ccee,
          renderDistance - 10,
          renderDistance
        ))
      : (scene.fog = "");
  });
}

function getCurrentDate() {
  let currentDate = new Date();
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  let year = currentDate.getFullYear();
  let hours = currentDate.getHours();
  let minutes = currentDate.getMinutes();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  if (hours < 10) {
    hours = "0" + hours;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  return day + "." + month + "." + year + " " + hours + ":" + minutes;
}

function saveWorld(worldName, worldImage, worldDate) {
  let worldData = getWorldData(objects);
  world = [worldImage, worldName, worldDate, worldData, "minecraft.kndxiu.xyz"];

  let data = JSON.stringify(world);
  let filename = worldName + ".json";
  let file = new Blob([data], { type: "application/json" });
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();
}

function loadWorld() {
  let fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.accept = ".json";

  fileInput.addEventListener("change", (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      world = JSON.parse(reader.result);
      if (world[4] === "minecraft.kndxiu.xyz") {
        settings.classList.add("worldLoad");

        document.getElementById(
          "loadWorldImg"
        ).style.backgroundImage = `url('${world[0]}')`;
        document.getElementById("loadWorldName").innerHTML = `${world[1]}`;
        document.getElementById("loadWorldDate").innerHTML = `(${world[2]})`;
      } else {
        settings.classList.add("error");
      }
    };
  });

  fileInput.click();
}

function saveWorldMenu() {
  document.getElementById("worldName").value = "";
  settings.classList.add("worldSave");
  worldImage = renderer.domElement.toDataURL();
  worldDate = getCurrentDate();

  document.getElementById(
    "worldImg"
  ).style.backgroundImage = `url('${worldImage}')`;
  document.getElementById("worldDate").innerHTML = `(${worldDate})`;
}

document.getElementById("save").addEventListener("click", () => {
  saveWorldMenu();
});

document.getElementById("saveWorldButton").addEventListener("click", () => {
  worldName = document.getElementById("worldName").value;
  if (worldName == "") {
    worldName = "My World";
  }
  saveWorld(worldName, worldImage, worldDate);
});

document.getElementById("load").addEventListener("click", () => {
  loadWorld();
});

document.getElementById("create").addEventListener("click", () => {
  createWorld();
});

document.getElementById("settings").addEventListener("click", () => {
  settings.classList.add("settingsScreen");
});

document.getElementById("loadWorldButton").addEventListener("click", () => {
  loadWorldFromFile(world);
});

function isPositionOccupied(position) {
  for (let i = 0; i < objects.length; i++) {
    if (
      objects[i].position.x === position.x &&
      objects[i].position.y === position.y &&
      objects[i].position.z === position.z
    ) {
      return true;
    }
  }
  return false;
}

function placeBlock() {
  if (
    intersections.length > 0 &&
    locked === true &&
    intersections[0].distance < 5
  ) {
    const canBeRotated = blocks[Object.keys(blocks)[currentBlock]].rotate;
    let face = intersections[0].face;
    let clickedObject = intersections[0].object;
    let matrix = new THREE.Matrix4();
    matrix.extractRotation(clickedObject.matrix);
    let normal = face.normal.clone();
    normal.applyMatrix4(matrix);

    let xyz;
    if (normal.x === 1) {
      //Strona: prawa
      xyz = [1, 0, 0];
    } else if (normal.x === -1) {
      //Strona: lewa
      xyz = [-1, 0, 0];
    } else if (normal.y === 1) {
      //Strona: góra
      xyz = [0, 1, 0];
    } else if (normal.y === -1) {
      //Strona: dół
      xyz = [0, -1, 0];
    } else if (normal.z === 1) {
      //Strona: przód
      xyz = [0, 0, 1];
    } else if (normal.z === -1) {
      //Strona: tył
      xyz = [0, 0, -1];
    }

    // Sprawdzenie, czy pozycja jest zajęta
    const isPositionOccupied = objects.some((obj) =>
      obj.position.equals(
        new THREE.Vector3(
          outlineMesh.position.x + xyz[0],
          outlineMesh.position.y + xyz[1],
          outlineMesh.position.z + xyz[2]
        )
      )
    );

    if (!isPositionOccupied) {
      let cube = new THREE.Mesh(
        cubeGeo,
        blocks[Object.keys(blocks)[currentBlock]].texture
      );

      if (canBeRotated) {
        // Obracanie kostki
        if (normal.x === 1 || normal.x === -1) {
          cube.rotateOnAxis(new THREE.Vector3(0, 0, 1), -Math.PI / 2);
          cube.userData.rotation = 1;
        } else if (normal.z === 1 || normal.z === -1) {
          cube.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
          cube.userData.rotation = 2;
        }
      } else {
        // Ustawienie rotacji na zero, jeśli kostka nie może być obrócona
        cube.userData.rotation = 0;
      }

      cube.position.set(
        outlineMesh.position.x + xyz[0],
        outlineMesh.position.y + xyz[1],
        outlineMesh.position.z + xyz[2]
      );

      cube.castShadow = true;
      cube.receiveShadow = true;
      cube.userData.texture = currentBlock;
      objects.push(cube);
      scene.add(cube);

      renderer.shadowMap.needsUpdate = true;
    }
  }
}

function removeBlock() {
  if (
    intersections.length > 0 &&
    locked === true &&
    intersections[0].distance < 5
  ) {
    intersections[0].object.material.forEach((element) => {
      element.dispose();
    });
    intersections[0].object.geometry.dispose();
    scene.remove(intersections[0].object);
    objects.splice(objects.indexOf(intersections[0].object), 1);
    renderer.shadowMap.needsUpdate = true;
  }
}

function pickBlock() {
  if (
    intersections.length > 0 &&
    locked === true &&
    intersections[0].distance < 5
  ) {
    currentBlock = intersections[0].object.userData.texture;

    const activeSlot = document.querySelector(".slot.active");

    activeSlot.classList.remove("active");
    slot[currentBlock].classList.add("active");
  }
}

function handleMouseUp(event) {
  if (!isPaused) {
    const mouseButton = event.button;
    if (mouseButton === 0) {
      removeBlock();
      document.getElementById("leftBtn").classList.remove("active");
      heldBlock.position.y = -0.75;
    } else if (mouseButton === 2) {
      placeBlock();
      document.getElementById("rightBtn").classList.remove("active");
      heldBlock.position.y = -0.75;
    } else if (mouseButton === 1) {
      pickBlock();
      document.getElementById("midBtn").classList.remove("active");
    }
  }
}

function handleMouseDown(event) {
  if (!isPaused) {
    const mouseButton = event.button;
    if (mouseButton === 0) {
      document.getElementById("leftBtn").classList.add("active");
      heldBlock.position.y = -0.85;
    } else if (mouseButton === 2) {
      document.getElementById("rightBtn").classList.add("active");

      heldBlock.position.y = -0.85;
    } else if (mouseButton === 1) {
      document.getElementById("midBtn").classList.add("active");
    }
  }
}

canvas.addEventListener("mousedown", handleMouseDown);

canvas.addEventListener("mouseup", handleMouseUp);

let movementControls = controls.getObject();

let moveSpeed = 2;

document.addEventListener("keydown", function (event) {
  if (!isPaused) {
    keyboard[event.keyCode] = true;
    if (document.getElementById(`${event.key.toLowerCase()}`)) {
      document
        .getElementById(`${event.key.toLowerCase()}`)
        .classList.add("active");
    }
  }
});

document.addEventListener("keyup", function (event) {
  if (!isPaused) {
    keyboard[event.keyCode] = false;
    if (document.getElementById(`${event.key.toLowerCase()}`)) {
      document
        .getElementById(`${event.key.toLowerCase()}`)
        .classList.remove("active");
    }
  }
});

var currentSpeed = new THREE.Vector3();

let friction = 0.1;

function updatePosition() {
  // obliczanie wektora kierunku w płaszczyźnie
  var cameraDirection = new THREE.Vector3();
  movementControls.getWorldDirection(cameraDirection);
  cameraDirection.y = 0; // wyzerowanie ruchu w osi Y

  // obliczanie wektora kierunku ruchu
  var direction = new THREE.Vector3();
  var moveForward = new THREE.Vector3(
    cameraDirection.x,
    0,
    cameraDirection.z
  ).normalize();
  var moveSideways = new THREE.Vector3(
    cameraDirection.z,
    0,
    -cameraDirection.x
  ).normalize();

  if (keyboard[87] || keyboard[83]) {
    direction.add(moveForward.multiplyScalar(keyboard[87] ? 1 : -1));
  }
  if (keyboard[65] || keyboard[68]) {
    direction.add(moveSideways.multiplyScalar(keyboard[65] ? 1 : -1));
  }
  if (keyboard[32] || keyboard[16]) {
    direction.y = keyboard[32] ? 1 : -1;
  }

  direction.normalize(); // normalizacja wektora

  // obliczanie przyspieszenia kamery
  var acceleration = new THREE.Vector3();
  acceleration.copy(direction).multiplyScalar(moveSpeed * dt);

  // aktualizacja prędkości kamery
  currentSpeed.add(acceleration);
  currentSpeed.multiplyScalar(1 - friction);

  // obliczanie przesunięcia kamery
  movementControls.position.add(currentSpeed);
}

setInterval(() => {
  document.getElementById("fps").textContent = `FPS: ${fps}`;
}, 250);

let t = false;

let oldElapsedTime = 0;
let dt = 0.016;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  dt = elapsedTime - oldElapsedTime;
  oldElapsedTime = elapsedTime;
  fps = Math.round(1 / dt);
  raycaster.setFromCamera(pointer, camera);
  heldBlock.material = blocks[Object.keys(blocks)[currentBlock]].texture;
  heldBlock.material.needsUpdate = true;

  intersections = raycaster.intersectObjects(objects, false);
  if (intersections.length > 0 && intersections[0].distance < 5) {
    outlineMesh.material.opacity = 0.25;
    intersect = intersections[0];
    let object = intersect.object;
    outlineMesh.position.set(
      Math.round(object.position.x),
      Math.round(object.position.y),
      Math.round(object.position.z)
    );
  } else {
    outlineMesh.material.opacity = 0;
  }

  for (let i = 0; i < objects.length; i++) {
    const distance = camera.position.distanceTo(objects[i].position);

    if (distance > renderDistance) {
      objects[i].visible = false;
    } else {
      objects[i].visible = true;
    }
  }

  updatePosition();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);

  if (t) {
    objects.forEach((object) => {
      for (let i = 0; i < 6; i++) {
        object.material[i].visible = false;
        object.material[2].visible = true;
      }
    });
  }
};

tick();
